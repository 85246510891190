define('ember-cli-server-variables/services/server-variables', ['exports', 'ember'], function (exports, _ember) {
  var isBlank = _ember['default'].isBlank;
  var dasherize = _ember['default'].String.dasherize;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    unknownProperty: function unknownProperty(serverVar) {
      var ENV = this.get('env');
      var prefix = ENV.serverVariables.tagPrefix || ENV.modulePrefix;
      var dasherizedVar = dasherize(serverVar);

      var content = _ember['default'].$('head meta[name=' + prefix + '-' + dasherizedVar + ']').attr('content');
      if (!isBlank(content)) {
        try {
          return JSON.parse(content);
        } catch (e) {
          // content was not JSON
          return content;
        }
      }
    }
  });
});